<template>
  <div class="page layout min-w-[860px]">
    <div class="header-bar flex">
      <div class="flex">
        <div class="logo flex center">
          <img :src="getPlatformInfo.website_logo" @error="logoAbnormal" />
        </div>

        <div class="tab-menu-list flex">
          <div class="tab-menu-item flex" v-for="(el, i) in menus" :key="i" :class="{ active: el.to === currentRouter }"
            @click="$router.push(el.to)">
            <div class="icon">
              <img :src="el.icon" />
            </div>
            <div class="text">{{ el.text }}</div>

            <div class="number_mark" v-if="el.to === '/my_record' && getUnreads">
              {{ getUnreads }}
            </div>
          </div>
        </div>
      </div>

      <div class="right flex">
        <div class="message mr flex center relative">
          <div class="icon hover-bg flex center" @click="$router.push('/notice')">
            <img style="width: 20px; height: 20px" src="./../assets/tab-menus/message.svg" />
          </div>
        </div>

        <div class="vip-info mr-1 !pl-1 flex s_hover_bg" @click="jumpGoldProducts">
          <div class="icon flex">
            <img v-if="true" src="./../assets/tab-menus/vip-86.svg" />
          </div>
          <div class="vip-text" style="margin-left: 6px; font-size: 13px">
            {{ getInfo.title }}
          </div>
        </div>

        <div class="rank-text mr-1 over_text" style="max-width: 280px; font-size: 15px">
          {{ getInfo.username }}
        </div>

        <div class="avatar ml">
          <el-dropdown @command="clickDropDown">
            <img :src="getInfo.avatar" />
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="link">邀请链接</el-dropdown-item> -->
              <el-dropdown-item command="edit_info">个人信息</el-dropdown-item>
              <el-dropdown-item command="feedback">意见反馈</el-dropdown-item>
              <el-dropdown-item command="rule">平台规则</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 意见反馈 -->
    <myDialog title="意见反馈" width="600px" :state="dialogState"
      @close="() => (imageFileFocus(false), (dialogState = false))">
      <el-form :model="formData" :rules="formRules" ref="formRef">
        <el-form-item prop="type" label="意见类型">
          <el-select v-model="formData.type" class="mt" style="width: 100%">
            <el-option value="靶机意见" label="靶机意见"></el-option>
            <el-option value="会员意见" label="会员意见"></el-option>
            <el-option value="其它意见" label="其它意见"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="title" label="意见标题">
          <el-input v-model="formData.title" placeholder="请输入意见标题" />
        </el-form-item>

        <el-form-item prop="content" label="意见描述">
          <el-input type="textarea" v-model="formData.content" placeholder="请输入意见描述" />
        </el-form-item>

        <el-form-item prop="show_img_url" label="报错截图">
          <el-col :span="24">
            <div class="item">
              <div class="flex">
                <div class="input upload_area">
                  <div class="placeholder truncate break-all" v-if="formData.show_img_url">
                    {{ formData.show_img_url }}
                  </div>
                  <div class="placeholder truncate break-all" v-else>请上传 .jpg, .png, .jpeg 的图像文件</div>
                </div>
                <div class="input upload_button" @click="imageFileFocus">点击上传</div>
                <input type="file" ref="file" accept=".jpg, .png, .jpeg" @change="uploadFile" hidden />
              </div>
            </div>
          </el-col>
        </el-form-item>

        <el-form-item prop="email" label="电子邮箱">
          <el-input v-model="formData.email" placeholder="预留你的电子邮箱" />
        </el-form-item>

        <el-form-item prop="captcha_value" label="验证码">
          <el-col :span="24">
            <div class="item">
              <div class="flex">
                <el-input v-model="formData.captcha_value" placeholder="请输入验证码" />
                <div class="input upload_button captcha ml-[12px]" @click="getCaptcha">
                  <img :src="captcha_data" class="!h-[50px]" />
                </div>
              </div>
            </div>
          </el-col>
        </el-form-item>

        <el-form-item>
          <div class="flex center">
            <confirm-prompt prompt="意见反馈确认提示" :confirmBeforeCall="validateForm"
              @confirmCall="submitFeedback">提交反馈</confirm-prompt>
            <el-button class="ml-3" @click="clearFormData">清空输入</el-button>
          </div>
        </el-form-item>
      </el-form>
    </myDialog>

    <div class="sub-page">
      <router-view />

      <div class="icp_info flex center">
        <a :href="getPlatformInfo.icp_record_link" target="_blank" class="icp">{{ getPlatformInfo.icp_record_number
          }}</a>
        <a :href="getPlatformInfo.ga_record_link" class="ga flex ml" target="_blank">
          <img :src="getPlatformInfo.ga_record_icon" alt class="ga-icon" width="30px" />
          <span>{{ getPlatformInfo.ga_record_number }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { Message, MessageBox } from 'element-ui'
import { mapGetters } from 'vuex'
import { UploadLocalstorage, GetImageCaptcha, AddFeedBack } from '@/api/user'
import webSocket from '@/api/webSocket'
import myDialog from '@/components/myDialog'
import confirmPrompt from '@/components/confirmPrompt'
import { formData } from '@/utils/request_config'

export default {
  components: { myDialog, confirmPrompt },
  data() {
    return {
      BASE_URL: '../assets/tab-menus/',
      menus: [
        {
          icon: require(`../assets/tab-menus/menu-1.svg`),
          text: '我的空间',
          to: '/my_record',
        },
        {
          icon: require(`../assets/tab-menus/menu-2.svg`),
          text: '漏洞靶标',
          to: '/target',
        },
        {
          icon: require(`../assets/tab-menus/menu-3.svg`),
          text: '综合场景',
          to: '/scene',
        },
        {
          icon: require(`../assets/tab-menus/menu-4.svg`),
          text: '排名',
          to: '/ranking',
        },
        {
          icon: require(`../assets/tab-menus/menu-5.svg`),
          text: '订阅',
          to: '/activity',
        },
      ],
      dialogState: false,
      formData: {
        type: '',
        title: '',
        img_url: '',
        show_img_url: '',
        email: '',
        content: '',
        captcha_id: '',
        captcha_value: '',
      },
      formRules: {
        type: [{ required: true, message: '请选择意见类型', trigger: ['submit', 'change'] }],
        title: [{ required: true, message: '请输入意见标题', trigger: ['submit', 'blur'] }],
        show_img_url: [{ required: true, message: '请上传报错截图', trigger: 'submit' }],
        content: [{ required: true, message: '请输入意见描述', trigger: ['submit', 'blur'] }],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: ['submit', 'blur'] },
          { type: 'email', message: '请输入正确的邮箱格式', trigger: ['submit', 'blur'] }
        ],
        captcha_value: [{ required: true, message: '请输入验证码', trigger: ['submit', 'blur'] }],
      },
      captcha_data: '',
    }
  },
  computed: {
    currentRouter() {
      return this.$route.matched[1].path
    },
    ...mapGetters(['getInfo', 'getUnreads', 'getPlatformInfo']),
  },
  methods: {
    logoAbnormal() {
      this.$store.commit({
        type: 'setLogList',
        key: 'website_logo',
        value: {
          title: '图片加载异常: ' + getPlatformInfo.website_logo,
          date: new Date()
        }
      })
    },
    jumpGoldProducts() {
      this.$router.push('/activity')
    },
    clickDropDown(val) {
      switch (val) {
        // 跳转至用户个人信息
        case 'edit_info':
          this.$router.push('/user_info')
          break

        // 退出登录
        case 'logout':
          MessageBox.confirm('此操作将使登录状态失效, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.$router.replace('/login')
              store.dispatch('setTokenSync', '')
              Message.success('已退出登录')
            })
            .catch(() => { })
          break

        // 跳转至链接分享
        case 'link':
          this.$router.push('/activity/invite_link')
          break

        // 打开意见反馈dialog
        case 'feedback':
          for (let key in this.formData) {
            this.formData[key] = ''
          }
          this.dialogState = true
          this.getCaptcha()
          break
        // 平台规则
        case 'rule':
          this.$router.push('/rule')
          break
      }
    },
    // 表单校验
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          valid ? resolve(true) : reject(false)
        })
      })
    },
    async submitFeedback() {
      await this.validateForm()
      this.$store.dispatch('setGlobalLoadingSync', true)
      await AddFeedBack({ ...this.formData, show_img_url: null })

      this.dialogState = false
      this.imageFileFocus(false)
      Message.success('提交成功')
    },
    clearFormData() {
      for (let key in this.formData) {
        this.formData[key] = ''
      }
      this.imageFileFocus(false)
      this.getCaptcha()
    },
    imageFileFocus(flag = true) {
      this.$nextTick(() => {
        if (!flag) {
          this.$refs.file.value = ''
        } else {
          this.$refs.file.click()
        }
      })
    },
    async uploadFile() {
      this.$store.dispatch('setGlobalLoadingSync', true)
      const files = this.$refs.file.files
      const res = await UploadLocalstorage({ file: files[0] })

      this.formData.show_img_url = files[0].name
      this.formData.img_url = res.data.file_url

      this.$refs.formRef.clearValidate('show_img_url')
      Message.success('上传成功')
    },
    async getCaptcha() {
      const res = await GetImageCaptcha()

      this.captcha_data = res.data.captcha_data
      this.formData.captcha_id = res.data.captcha_id
    },
  },
  created() {
    webSocket(() => { })
  },
}
</script>

<style lang="less" scoped>
.icp_info {
  position: fixed;
  right: 0;
  bottom: 5px;
  width: 100%;
  height: 20px;
  line-height: 20px;
  z-index: 1000000;

  a {
    font-size: 12px;
    text-decoration: none;

    &:hover,
    &:hover span {
      color: steelblue;
      text-decoration: underline;
    }
  }
}

.item {
  width: 100%;
  display: block;

  .upload_area {
    width: calc(100% - 176px);
    line-height: 50px;

    .placeholder {
      color: #afafaf;
      background-color: #2b2b46;
      padding: 0 15px;
      border-radius: 10px;
    }
  }

  .captcha {
    overflow: hidden;
    padding: 2px;
    border-color: rgba(255, 255, 255, 0.1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .upload_button {
    width: 164px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    background-color: #2b2b46;
    border-radius: 10px;
  }

  .textarea {
    height: 100px;
    resize: none;
    padding: 15px;
  }
}

.header-bar {
  height: 60px;
  width: 100%;
  background-color: #1f1f35;
  padding: 0 35px;
  user-select: none;

  .tab-menu-list {
    margin-left: 40px;

    .icon {
      width: 20px;
      height: 20px;
      transition: 0.4s;
      filter: grayscale(100%);
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .icon::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 10px;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15), transparent);
      top: calc(50% - 3px);
      left: -7px;
    }

    .text {
      font-size: 14px;
      transition: 0.4s;
      margin-left: 10px;
    }

    .tab-menu-item {
      color: #8a90a0;
      margin-right: 36px;
      cursor: pointer;
      position: relative;

      .number_mark {
        position: absolute;
        right: -18px;
        top: -10px;
        background-color: #dc3545;
        color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
      }

      &.active {
        .text {
          color: #ffc200;
        }

        .icon {
          filter: none;
        }

        .icon::after {
          content: '';
          position: absolute;
          width: 36px;
          height: 12px;
          background-image: linear-gradient(to right, rgba(255, 194, 0, 0.7), transparent);
          top: calc(50% - 3px);
          left: -7px;
        }
      }
    }
  }

  .logo {
    height: 60px;

    img {
      height: 60%;
    }
  }

  .right {
    font-size: 15px;

    .message {
      .icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }

    .rank-text {
      font-weight: 600;
    }
  }

  .avatar img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
    border: 2.5px solid #fff;
  }
}

@media screen and (max-width: 1050px) {
  .tab-menu-item {
    .text {
      display: none;
    }
  }
}
</style>
