import router from "@/router"
import store from "@/store"
import axios from "axios"
import { Loading, Message } from "element-ui"
import Vue from "vue"

// BASE_URL是在public目录下的一个静态js文件中定义的变量
const instance = axios.create({
  baseURL: BASE_URL || process.env.VUE_APP_URL,
})

let _loading = null
function loading(status) {
  if (status) {
    _loading = Loading.service()
  } else {
    _loading && (Vue.nextTick(() => {
      _loading.close()
    }))
  }
}

instance.interceptors.request.use(function (config) {
  // console.log(config.url, config.loading == undefined ? true : false)
  config.headers.token = store.getters.getToken
  // config.headers["X-Forwarded-For"] = "127.0.0.1"

  // 将当前请求控制器添加到requestQueue数组中
  // 在页面组件实例销毁时，会遍历requestQueue数组逐一取消请求
  const controller = new AbortController()
  controller.signal.url = config.url
  config.signal = controller.signal
  store.dispatch('setRequestQueueSync', controller)

  if (config.loading !== false) {
    loading(true)
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(
  function (response) {
    loading(false)
    store.dispatch("setGlobalLoadingSync", false)

    // 传回内容非固定格式，例如：Blob对象, File对象等...直接返回
    if (response.data instanceof Blob) {
      return {
        blob: response.data,
        fileName: response.headers["content-disposition"].match(/filename=(.+)/)[1]
      }
    }

    const { code, msg } = response.data

    // 登录状态失效
    if (code === 401 && msg.indexOf("未登录") > -1) {
      Message.error("登录状态失效，请重新登录")
      store.dispatch("setTokenSync", "")
      router.replace("/login")
      return Promise.reject(msg)
    }

    // 状态码为0或401，抛出异常
    if (!code || code === 401) {
      Message.error(msg)
      return Promise.reject(msg)
    }

    return response.data
  },
  function (error) {
    loading(false)
    store.dispatch("setGlobalLoadingSync", false)

    if (!error.response) {
      return Promise.reject(error)
    }

    const { data, status } = error.response
    const statusDesc = [
      {
        status: 404,
        message: "请求地址或资源不存在"
      },
      {
        status: 500,
        message: data.msg
      }
    ]

    const filter = statusDesc.filter(el => el.status === status)
    if (filter[0]) {
      Message.error(filter[0].status + " " + filter[0].message)
      return Promise.reject(filter[0].message)
    }

    return Promise.reject(error)
  }
)

export default instance
