import store from "@/store"
import { Message } from "element-ui"

// let _index = 0, testMessage = [
//   { "code": 1, "msg": "kvmMessage", "data": "创建Openvpn Server" },
//   { "code": 1, "msg": "kvmMessage", "data": "定义域 openvpn-server-8c4f50dd-809d-42b0-a69e-811a1b2050d4（从 usr/src/kvmLoad//openvpn-server-8c4f50dd-809d-42b0-a69e-811a1b2050d4.xml）\n" },
//   { "code": 1, "msg": "kvmMessage", "data": "正在生成与拷贝openvpn.service...." },
//   { "code": 1, "msg": "kvmMessage", "data": "虚拟机[mini2] 启动成功! 2/2" },
//   { "code": 1, "msg": "kvmMessage", "data": "FINISHED" },
// ]

// function testKvmServer() {
//   let timer = setInterval(() => {
//     _index < testMessage.length &&
//     (store.dispatch("setMessageListSync", testMessage[_index]))
//     console.log(store.getters.getMessageList)

//     if (_index === testMessage.length - 1) {
//       clearInterval(timer)
//     } else {
//       _index++
//     }
//   }, 1000)
// }
// testKvmServer()

export default function (openCallback, messageCallback) {
  const ws = new WebSocket(WEB_SOCKET_BASE_URL)

  ws.onopen = () => {
    const params = JSON.stringify({ "token": store.getters.getToken })
    ws.send(params)

    openCallback && openCallback()
  }

  ws.onmessage = (evt) => {
    const response = JSON.parse(evt.data)
    messageCallback && messageCallback(response)

    // if (response.data) {
    //   store.dispatch("setMessageListSync", response)
    // }

    // if (!response.code || response.msg === "alert") {
    //   Message.error(`虚拟仿真异常：${ response.msg }`)
    // }
  }

  ws.onerror = (error) => {
    console.warn('websocket 连接错误: ', error)
    // Message.error("websocket 连接错误", error)
  }
}
