<template></template>

<script>
import store from "@/store"
import { GetLoginInfo } from '@/api/user'

export default {
  computed: {
    token() {
      return this.$route.query.token || null
    },
  },
  methods: {
  },
  created() {
    if (this.token) {
      store.dispatch("setTokenSync", this.token)
      GetLoginInfo()
      this.$store.dispatch('setPlatformInfoSync')
      this.$router.replace("/my_record/my_record_index")
      this.$message.success("登录成功")
    }
  },
}
</script>

<style></style>
