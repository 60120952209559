<template>
  <div id="app" v-loading="getGlobalLoading">
    <router-view />
  </div>
</template>

<script>
import scaleBox from '@/components/scaleBox.vue'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: { scaleBox },
  methods: {
    handleBeforeUnload() {
      const navigationType = performance.getEntriesByType('navigation')[0].type

      if (navigationType !== 'reload') {
        store.dispatch('setTokenSync', '')
      }
    },
    // 预设alert提示选项
    setAlertOptions({ alert, msg }) {
      const messages = {
        '扫码异常': '扫码异常,请刷新后重新扫码',
        '微信未绑定': '微信未绑定,手机登录即可绑定微信',
        '账户已禁用': '该账户已禁用',
        '已绑定其他用户': '该微信已绑定其他用户,请先解绑后再绑定当前用户',
        '绑定成功': '绑定成功',
      }

      if (alert == '1' && msg) {
        messages[msg] && this.$message.warning(messages[msg])
      }
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getGlobalLoading']),
    querys() {
      return this.$route.query || {}
    },
  },
  watch: {
    querys: {
      handler(val) {
        this.setAlertOptions(val)
      },
      immediate: true,
    },
  },
  mounted() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload)
  },
  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.handleBeforeUnload)
  },
  created() {
    this.$store.dispatch('setPlatformInfoSync')
  },
}
</script>

<style lang="less">
body {
  height: 100vh;
}
</style>
